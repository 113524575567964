.App {
  text-align: center;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  background: var(--white);
  color: var(--black);
  min-height: 100vh;
  max-height: 100vh;
  position: relative;
  overflow-y: hidden;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
/* @font-face {
  font-family: "roc-grotesk", sans-serif;
  font-weight: 400;
  font-style: normal; 
} */
/* .App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {
  color: #61dafb;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
.home{
  /* border: 1px solid black; */
  display: flex;
  height: 100%;
  justify-content: center;
}
.left-container{
  /* border: 1px red solid; */
  display: flex;
  /* flex: 1.2; */
  width: 60% !important;
}
.video-style{
  display: flex;
  /* overflow-y: hidden; */
  width: 100%;
  /* width="100%" height="100%" */
}
.middle-container{
  display: inline-block;
  /* border: 1px red solid; */
  top: 15px !important;
  height: 130px !important;
  text-align: center;
  justify-content: middle;
  align-items: center;
  position: sticky !important;
  transform: translateX(-50%);
}
.middle-container a{
  /* border: 1px purple solid; */
  text-decoration: none;
  font-family: plantin, sans-serif;
  height: 100% !important;
  position: relative !important;
}
.middle-container a:hover{
  color: black;
}
.middle-container h1{
  /* border: 1px green solid; */
  font-size: 400% !important;
  position: absolute !important;
  top: -87px !important;
  left: 43px !important;
}
.home-bottom a{
  color: white !important;
  font-size: 8px !important;
}
.middle-container h1:hover{
color: black;
}
.middle-container img{
  /* border: 1px yellow solid; */
  height: 100% !important;
  position: relative !important;
  animation: Rotation 20s linear infinite;
}
@keyframes Rotation {
  0%{
    transform: rotate(0deg)
  }
  100%{
    transform: rotate(360deg)
  }
}
.right-container{
  /* border: 1px yellow solid; */
  display: flex;
  /* position: absolute !important; */
  left: -150px !important;
  /* flex: 1; */
  width: 40% !important;
}
.home-text{
  max-height: 100vh;
  justify-content: center;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-right: min(81px);
}
h2{
  font-family: plantin-condensed, sans-serif;
  font-stretch: ultra-condensed !important;
  /* font-weight: 700; */
  font-size: 700%;
}
h2 i{
  font-family: plantin, sans-serif;
}
p{
  font-family: IBM plex sans, sans-serif;
  font-weight: 400;
  line-height: 1.5;
  font-size: clamp(0.8rem, 1.15vw, 1.15rem);
}
a {
  text-decoration: underline;
  color: black;
}
a:hover{
  color: #B83C58;
}
.italics_p{
  font-weight: 500;
  font-style: italic;
}

@media screen and (max-width: 600px){
  .App{    
    min-height: auto !important;
    max-height: 100% !important;
    overflow-x: hidden;
  }
  .home{
    /* border: 1px solid black; */
    display: flex;
    height: auto !important;
    flex-direction: column-reverse;
  }
  .left-container{
    /* border: 1px red solid; */
    display: flex;
    flex: auto;
    width: 100% !important;
  }
  .middle-container{
    position: absolute !important;
    top: 465px !important;
    right: 50px  !important;
    /* transform: translateX(100%)  !important; */
    /* transform: translateY(400%)  !important; */
  }
  .right-container{
    padding-top: 10% !important;
    padding-left: 10% !important;
    width: 100% !important;
    padding-bottom: 10% !important;
  }
}


/*lorem page*/
#copyOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  z-index: 100;
  transition: opacity 1.5s linear;
}
/* Copy Modal */
.copy-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 20px;
  font-size: 20px;
  border-radius: 5px;
}

.modal--show {
    visibility: visible;
    opacity: 0;
}

.modal--hidden {
    visibility: hidden;
    opacity: 1;
    transition: visibility 0s 1.5s;
}

#copyText {
  margin-top: 40vh;
}

#copyText p {
  font-size: 30px;
  font-family: 'Poppins';
  font-weight: bold;
  color: #fff;
}

.loremsite {
  display: flex;
  height: 100vh;
  width: 100vw;
  font-family: "Space Grotesk", serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}
.main-content {
  display: flex;
  width: 100%;

}
.left-lorem-container{
  width: 40%;
  /* background-color: #EA6200 !important; */
  padding: 80px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
}
.left-lorem-container .title{
  /* color: #FFFEE3 !important; */
  font-family: "roc-grotesk", sans-serif;
  font-weight: 700;
  font-style: normal;
  /* align-content: start !important; */
  text-align: left !important;
  font-size: 56px !important;
}
.description {
  font-family: "Space Grotesk", serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  /* color: #FFFEE3; */
  text-align: left !important;
  font-size: 18px;
  margin-bottom: 20px;
}
.language-button {
  display: flex;
  gap: 5px;
  margin-bottom: 20px;
}
.plaintain-button, .platano-button {
  background-color: transparent;
  border: 2px solid;
  text-transform: uppercase !important;
  border-radius: 20px !important;;
  /* color: #FFFEE3; */
  font-size: 14px;
  font-family: "Space Grotesk", serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  padding: 5px 16px;
  cursor: pointer;
}

/* .plaintain-button:hover, .platano-button:hover {
  background-color: #FFFEE3;
  color: #EA6200;
} */
.form{
  text-align: left !important;
}
.paragraph-number {
  width: 300px;
  padding: 10px 16px;
  text-align: left;
  border: 2px solid;
  background: transparent;
  /* color: #FFFEE3; */
  font-family: "Space Grotesk", serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  font-size: 16px;
  margin-bottom: 0 !important;
}
.paragraph-number::placeholder {
  /* color: #FFFEE3; */
  opacity: 1; /* Ensures full color visibility */
}

.generate-button {
  width: 300px;
  margin-top: 10px;
  padding: 10px 20px;
  text-transform: uppercase !important;
  /* background-color: #FFFEE3; */
  /* color: #EA6200; */
  border: 2px solid ;
  cursor: pointer;
  font-size: 14px;
  font-family: "Space Grotesk", serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}

/* .generate-button:hover {
  background-color: #000;
  color: #FFFEE3;
} */

.made-by{
  position: absolute;
  bottom: 20px; /* Adjust as needed */
  left: 20px;
  text-align: left;
}
.credit{
  font-weight: 400;
  font-family: 'VulfSansDemo';
  /* color: #FFFEE3; */
  font-size: 12px;
  font-family: "Space Grotesk", serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}
.credit a{
  /* color: #FFFEE3 !important; */
  text-decoration: none;
}
/* .linkwave{
  text-decoration: underline; 
  -webkit-text-decoration-style: wavy !important;
  -moz-text-decoration-style: wavy !important;
  text-decoration-style: wavy !important;
} */
.linkwave {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="5"><path d="M0,3 Q2,6 4,3 T8,3 T12,3" stroke="white" stroke-width="1" fill="transparent"/></svg>');
  background-repeat: repeat-x;
  background-position: bottom;
  background-size: 8px 3px;
  text-decoration: none !important;
  padding-bottom: 2px;
}
/* .linkwave a {
  text-decoration: underline;
  -webkit-text-decoration-style: wavy !important;
  -moz-text-decoration-style: wavy !important;
  text-decoration-style: wavy !important;
} */
/* 
.paragraph-number {
  max-width: 80px;
  margin: 0;
  margin-right: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
  height: 37px;
  transition: box-shadow 500ms;
} */

/* .paragraph-number:focus {
  box-shadow: 0px 0px 50px 5px #f61b1b;
} */

.right-lorem-container{
  width: 60%;
  padding: 80px !important;
  text-align: right;
  /* background-color: #FFFEE3 !important; */
  overflow-y: scroll;
  scroll-behavior: auto;;
}

.copy-button {
  /* background-color: #EA6200; */
  /* color: #FFFEE3; */
  position: absolute;
  top: 20px;
  right: 20px;
  border-radius: 20px !important;
  text-transform: uppercase !important;
  padding: 5px 16px;
  border: 2px solid;
  font-family: "Space Grotesk", serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  cursor: pointer;
  font-size: 14px;
}

/* .copy-button:hover, .copy-button:focus {
  background-color: #FFFEE3;
  color: #EA6200;
} */

/* .generate-button:hover,
.generate-button:focus {
  background-color: #EA6200;
  color: #FFFEE3;
} */

.generated-text {
  /* margin-top: 20px; */
  font-size: 16px;
  font-family: "Space Grotesk", serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  /* color: #EA6200; */
  text-align: left;
  padding-left: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
  /* background: #FFFEE3; */
}

.generated-text p {
  font-size: 16px;
  text-align: left;
  font-family: "Space Grotesk", serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  animation: fadein 2s;
  margin-bottom: 1em; /* Adds space below each paragraph */
}

.generated-text p:first-child {
  margin-top: 0;
}

.generated-text p:last-child {
  margin-bottom: 0;
}

label {
  display: block;
  font-weight: bold;
}

input {
  font-size: 16px;
  padding: 5px;
  margin: 10px 0;
  text-align: center;
  border: 1px solid #fff;
  color: #2C3238;
}

input:focus {
  outline: none;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.plaintain-button.active, .platano-button.active {
  /* background-color: #FFFEE3;
  color: #EA6200;
  border:  2px solid #FFFEE3; */
  /* font-size: 14px; */
  padding: 5px 16px;
}
/* Default selected */
/* .plaintain-button {
  background-color: #FFFEE3; 
  color: #EA6200;
  border: #EA6200;
} */


/* Responsive adjustments */
@media (max-width: 768px) {
  .loremsite {
    flex-direction: column;
    height: 100vh;
    /* background-color: #FFFEE3; */
  }
  .plaintain-button, .platano-button  {
    padding: 5px 45px !important;
  }

  .main-content {
    flex-direction: column;
    height: 100vh;
  }

  .left-lorem-container, .right-lorem-container {
    width: 100%;
    padding: 20px !important;
    /* height: 50vh; */
  }
  
  .left-lorem-container {
    align-items: flex-start;
    text-align: left;
  }
  
  .left-lorem-container .title,
  .description {
    text-align: center !important;
  }

  .language-button {
    justify-content: flex-start;
  }
  .description{
    margin-bottom: 0px;
  }

  .paragraph-number,
  .generate-button {
    width: 100%;
    max-width: 100%;
  }

  .right-lorem-container {
    text-align: left;
    padding: 20px !important;
    overflow-y: auto;
    /* background-color: #FFFEE3; */
  }

  .copy-button {
    /* display: block;
    margin:  0;
    bottom: 20px;
    left: 0px; */
    top: 93% !important;
    width: 90% !important;
  }

  .generated-text {
    text-align: left;
    padding: 10px;
    font-size: 14px;
  }

  /* .made-by {
    margin-top: auto;
    padding: 20px;
    bottom: 10px;
    left: 20px;
    text-align: left;
    color: #EA6200;
  }
  .credit{
    color: #EA6200;
  }
  .made-by a {
    color: #EA6200 !important;
  } */
  .made-by {
    display: none !important;
  }
}
